<template>
  <section id="WaveHeader">
    <v-card
      style="
        background: linear-gradient(rgba(157, 0, 255, 1), rgba(73, 2, 124, 1));
      "
      elevation="0"
    >
      <base-img
        :min-height="minHeight"
        :src="require('@/assets/miniweb/wave/miniweb_stylewave_head_v01.png')"
        class="white--text"
      >
        <!--------     AUTHENTICATOR DIALOG    ----------------------------------------->
        <kdw-dialog-authenticator
          v-model="dialogAuth"
          :parent="this"
          :navigate-next="navigateNext"
        />
        <!--------     Questions DIALOG    ----------------------------------------->
        <kdw-dialog-questions
          v-model="dialogQuestions"
          :parent-obj="this"
          :questions="questions"
        />
        <!--------     booking DIALOG    ----------------------------------------->
        <kdw-dialog-booking
          v-model="dialogOnlineBooking"
          :service-id="ServiceIDforOnlineBooking"
          :answer-id="answerId"
          :navigation-object="navigationObjectThisPage"
        />
        <v-responsive class="ma-auto" max-width="900" width="100%">
          <v-row>
            <v-col cols="12" sm="6">
              <!-- DESKTOP -->
              <div
                id="MiniWeb-StyleWave-Hearder-line1"
                class="text-left hidden-sm-and-down mt-md-16 pt-md-16 text-md-h3"
              >
                <div
                  class="mt-md-16 pt-md-16"
                  v-html="'<b>Changer de style</b>'"
                />
                <br />
                <div
                  id="MiniWeb-StyleWave-Hearder-line2"
                  class="text-subtitle-2"
                  v-html="
                    '<b>Ce format original</b> vous permettra de vous différencier'
                  "
                />
                <br />
                <v-row align="center" justify="space-around">
                  <v-col cols="12" sm="6">
                    <base-img
                      :src="require('@/assets/background/background001.jpg')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-spacer />
                    <kdw-btn id="Reservation"> Make a reservation </kdw-btn>
                  </v-col>
                </v-row>
              </div>
              <!-- MOBILE PHONE-->
              <div
                id="MiniWeb-StyleWave-Hearder-line1"
                class="text-left hidden-md-and-up mt-16 pt-12 text-h5"
              >
                <div v-html="'<b>Changer de style</b>'" />
                <div
                  id="MiniWeb-StyleWave-Hearder-line2"
                  class="text-subtitle-2"
                  v-html="
                    '<b>Ce format original</b> vous permettra de vous différencier'
                  "
                />
                <br />
                <v-row align="center" justify="space-around">
                  <v-col cols="6" sm="6">
                    <base-img
                      :src="require('@/assets/background/background001.jpg')"
                      class="ma-4"
                    />
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-spacer />
                    <kdw-btn id="Reservation"> Reservation </kdw-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <kdw-general-search-results-extension-based
                :search-event="searchResultFlag"
                service-provider-id="afe94c48-c5b6-4f08-90e2-64213306395c"
                @ServiceSelected="ServiceSelected($event)"
              />
            </v-col>
          </v-row>
        </v-responsive>
      </base-img>
    </v-card>
  </section>
</template>

<script>
// import { pickItem as CpickItem } from '@/controllers/Questions'
import {
  ANONYMOUS,
  ENDUSER,
  SERVICEPROVIDER,
  getUserType,
  onUserTypeChange,
} from "@/services/User";
import {
  pickItem as CpickItem,
  persistQuestions as CpersistQuestions,
  clearPersistQuestions as CclearPersistQuestions,
  isPersistQuestionsEmpty as CisPersistQuestionsEmpty,
} from "@/controllers/QuestionsV1";
export default {
  name: "SectionWaveHeader",

  data: () => ({
    searchResultFlag: false,
    postalCodeId: "002fb7f4-148a-49e9-b94e-9ea62012bc38",
    // Authenticator Dialog variables
    dialogAuth: false,
    ENDUSER: ENDUSER,
    ANONYMOUS: ANONYMOUS,
    // SERVICEPROVIDER: SERVICEPROVIDER,
    userType: null,
    signInConfig: {},
    signupFormFields: [],

    // Question Dialog variable
    dialogQuestions: false,
    questions: { question: [], category: "", subCategory: "" }, // all questions, default value to pre-render
    pickedItem: { name: "" },
    // Store a locale to check if the languge have switched.
    locale: "fr",
    // Variables for the boooking dialog
    dialogOnlineBooking: false,
    ServiceIDforOnlineBooking: "",
    answerId: "",
    navigationObjectThisPage: null,
    navigateNext: "HOME",
  }),
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
      // console.log('height from WaveHeader:', height)
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
  created: function () {
    setTimeout(() => {
      this.searchResultFlag = true;
    }, 100);
    this.navigationObjectThisPage = this;

    // Logic for the Dialog Questions:
    this.ANONYMOUS = ANONYMOUS;
    this.ENDUSER = ENDUSER;
    this.locale = this.$i18n.locale;
    this.userType = getUserType();
    this.unsubscribeUserType = onUserTypeChange((newUserType) => {
      console.log("changing userType in miniweb ", newUserType);
      this.userType = newUserType;
      if (this.userType === ENDUSER) {
        // console.log('hero - Seen as a User - Cpersist is run')
        CpersistQuestions();
      } else if (this.userType === SERVICEPROVIDER) {
        if (CisPersistQuestionsEmpty() === false) {
          // console.log('hero - Seen as a SP - Cpersist is not run & clear the array')
          CclearPersistQuestions();
          // this.$root.kdwsnackbar.alert(this.$i18n.t('hero.alertAuthenticatedNotEU'), -1)
          // we have to keep an alert if not the snackbar is not visible.
          alert(this.$i18n.t("hero.alertAuthenticatedNotEU"));
        }
        // navigate(this, 'ProHome') // SP goes Home
      }
      // ignore going to null or Anonymous or SP ?
      // console.log('hero - Seen as a ?? - Cpersist is not run')
    });
  },
  methods: {
    // The end user have selected this service TODO
    ServiceSelected(serviceSelected) {
      // console.log('serviceSelected')
      // console.log(serviceSelected)
      return CpickItem(
        this,
        serviceSelected,
        this.postalCodeId,
        this.$i18n.locale,
      );
    },
    cancelQuestions() {
      return true;
    },
  },
};
</script>
